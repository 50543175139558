import type { VueOidcSettings } from "vue3-oidc";
import { WebStorageStateStore } from "vue3-oidc";

import { authConfig, baseConfig } from "../config";

export { createOidc, useAuth, useOidcStore } from "vue3-oidc";

const appUrl = baseConfig.appUrl ?? "";

const redirect_uri = new URL("/oidc-callback", appUrl).toString();

const silent_redirect_uri = new URL("/silent-renew.html", appUrl).toString();

const post_logout_redirect_uri = new URL("/sign-out", appUrl).toString();

export const oidcSettings: VueOidcSettings = {
  authority: authConfig.oidcAuthority,
  scope: "openid email profile offline_access",
  client_id: authConfig.oidcClientId,
  redirect_uri,
  silent_redirect_uri,
  post_logout_redirect_uri,
  response_type: "code",
  loadUserInfo: true,
  automaticSilentRenew: true,
  monitorSession: true,
  validateSubOnSilentRenew: true,
  userStore: new WebStorageStateStore({
    prefix: "vue3-oidc",
    store: window.localStorage,
  }),
};
