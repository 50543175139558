import type { Logger, LoggerFactory } from "./log.service";

export interface ContextAwareError extends Error {
  getContext(): object;
}

export function isContextAwareError(error: ContextAwareError | Error): error is ContextAwareError {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (error as ContextAwareError).getContext !== undefined;
}

export class ErrorService {
  logger: Logger;

  constructor(logger: LoggerFactory) {
    this.logger = logger.getLogger("error");
  }

  public throwError = (error: Error | ContextAwareError): never => {
    this.logger.error(error.message)();
    throw error;
  };
}
