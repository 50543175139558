import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "vue3-oidc";

import { assertIsDefined } from "@/core/utils/typeHelpers";
import { fetchSurveyOrParticipation, useParticipation } from "@/stores/participation/participation";

import { useParticipationAuth } from "./stores/auth";
import { useTenants } from "./stores/tenants";

export enum ManagerRouteNames {
  HOME = "home",
  CLIENT = "client",
  PROJECT = "project",
  SURVEY = "survey",
}

export enum ParticipationRouteNames {
  PARTICIPATE = "participate",
  INTRO = "intro",
  QUESTION = "question",
  RESULT = "result",
  OUTRO = "outro",
}

export enum OidcRouteNames {
  OIDC_CALLBACK = "oidc-callback",
  SIGN_OUT = "sign-out",
}

const routes: RouteRecordRaw[] = [
  {
    name: ManagerRouteNames.HOME,
    path: "/",
    component: () => import("./views/clientList/ClientList.vue"),
    meta: {
      auth: "management",
    },
  },
  {
    name: OidcRouteNames.OIDC_CALLBACK,
    path: `/${OidcRouteNames.OIDC_CALLBACK}`,
    component: () => import("./views/OidcCallbackView.vue"),
  },
  {
    name: OidcRouteNames.SIGN_OUT,
    path: `/${OidcRouteNames.SIGN_OUT}`,
    component: () => import("./views/SignOutView.vue"),
  },
  {
    name: ManagerRouteNames.CLIENT,
    path: `/${ManagerRouteNames.CLIENT}/:id`,
    component: () => import("./views/clientDetails/ClientDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    name: ManagerRouteNames.PROJECT,
    path: `/${ManagerRouteNames.PROJECT}/:id`,
    component: () => import("./views/projectDetails/ProjectDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    name: ManagerRouteNames.SURVEY,
    path: `/${ManagerRouteNames.SURVEY}/:id`,
    component: () => import("./views/surveyDetails/SurveyDetails.vue"),
    props: true,
    meta: {
      auth: "management",
    },
  },
  {
    name: ParticipationRouteNames.PARTICIPATE,
    path: `/${ParticipationRouteNames.PARTICIPATE}`,
    children: [
      {
        name: ParticipationRouteNames.INTRO,
        path: `${ParticipationRouteNames.INTRO}/:surveyId`,
        component: () => import("./views/participate/intro/IntroView.vue"),
        props: true,
      },
      {
        name: ParticipationRouteNames.RESULT,
        path: ParticipationRouteNames.RESULT,
        component: () => import("./views/participate/results/ResultsView.vue"),
      },
      {
        name: ParticipationRouteNames.QUESTION,
        path: ParticipationRouteNames.QUESTION,
        component: () => import("./views/participate/question/QuestionView.vue"),
      },
      {
        name: ParticipationRouteNames.OUTRO,
        path: ParticipationRouteNames.OUTRO,
        component: () => import("./views/participate/outro/OutroView.vue"),
      },
    ],
  },
  // TODO: 404 page
];

if (import.meta.env.DEV === true) {
  routes.push({
    path: `/dev`,
    name: "dev",
    component: () => import("./views/DevView.vue"),
  });
}

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === "management") {
    await useAuth().autoAuthenticate();
    next(true);
    return;
  }
  next();
});

router.beforeEach(async (to, _from, next) => {
  const auth = useParticipationAuth();
  if (!to.path.startsWith(`/participate`)) {
    useTenants().customerTenants = [];
    auth.guestOrParticipationToken = undefined;
    next();
    return;
  }
  const jwt = to.hash.slice(1);
  auth.guestOrParticipationToken = jwt;

  const surveyOrParticipation = await fetchSurveyOrParticipation(jwt);
  assertIsDefined(surveyOrParticipation, "surveyOrParticipation");
  if (surveyOrParticipation.__typename === "Survey") {
    if (to.name === ParticipationRouteNames.INTRO) {
      next();
      return;
    }
    next({ name: ParticipationRouteNames.INTRO, params: { surveyId: surveyOrParticipation.id }, hash: to.hash });
    return;
  }
  if (surveyOrParticipation.__typename === "Candidate") {
    if (to.name === ParticipationRouteNames.INTRO) {
      next();
      return;
    }
    next({ name: ParticipationRouteNames.INTRO, params: { surveyId: surveyOrParticipation.survey.id }, hash: to.hash });
    return;
  }
  const participationStore = useParticipation();
  if (surveyOrParticipation.__typename === "Participation") {
    participationStore.participationId = surveyOrParticipation.id;
    next();
    return;
  }
  // TODO: error page?
  throw new Error(`Unexpected error: Expected Survey or Participation, got "${surveyOrParticipation.__typename}"`);
});
