import { DefaultApolloClient, provideApolloClient } from "@vue/apollo-composable";
import type { App } from "vue";

import { pullsApiClient } from "@/api/pulls";

provideApolloClient(pullsApiClient);

export const provideApis = (provideFunc: App["provide"]) => {
  provideFunc(DefaultApolloClient, pullsApiClient);
};
