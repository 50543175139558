import "cross-fetch/polyfill";

import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { createLink as createUploadLink } from "apollo-absinthe-upload-link/lib";
import { withScalars } from "apollo-link-scalars";
import fetch from "cross-fetch";
import type { IntrospectionQuery } from "graphql";
import { buildClientSchema } from "graphql";
import { DateResolver, DateTimeResolver, NonEmptyStringResolver, URLResolver } from "graphql-scalars";
import { useOidcStore } from "vue3-oidc";

import introspectionResult from "@/../graphql/introspection.json";
import { apiConfig } from "@/core/config";
import { useParticipationAuth } from "@/stores/auth";

import fragmentMatchers from "./helpers/apollo-fragment-matchers";
import type { TypedTypePolicies } from "./helpers/apollo-helpers";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
const simpleHttpLink: ApolloLink = createUploadLink({ uri: apiConfig.backend.api, ...fetch });

const authLink = setContext((_, { headers }) => {
  const participationAuth = useParticipationAuth();
  const oidcStore = useOidcStore();
  let pullsAuthorizationToken: string | null = null;
  let authorizationToken: string | null = null;

  if (participationAuth.guestOrParticipationToken) {
    pullsAuthorizationToken = `Token ${participationAuth.guestOrParticipationToken}`;
  }

  if (oidcStore.state.value.user) {
    authorizationToken = oidcStore.state.value.token ? `Bearer ${oidcStore.state.value.token}` : null;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...(authorizationToken && { authorization: authorizationToken }),
      "pulls-authorization": pullsAuthorizationToken ?? undefined,
    },
  };
});

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const typesMap = {
  Date: DateResolver,
  DateTime: DateTimeResolver,
  Datetime: DateTimeResolver,
  Uri: URLResolver,
  ID: NonEmptyStringResolver,
};

const scalarsLink = withScalars({
  schema,
  typesMap,
  validateEnums: true,
});

const httpLink = ApolloLink.from([scalarsLink, simpleHttpLink]);

const link = authLink.concat(httpLink);

const typePolicies: TypedTypePolicies = {};

const cache = new InMemoryCache({ typePolicies, possibleTypes: fragmentMatchers.possibleTypes });

export const pullsApiClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: "network-only",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
      refetchWritePolicy: "overwrite",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});
