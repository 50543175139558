<template>
  <div :style="tenantStyles">
    <router-view v-slot="{ Component }">
      <transition
        :enter-active-class="$style.fadeEnterActive"
        :leave-active-class="$style.fadeLeaveActive"
        :enter-from-class="$style.fadeInvisible"
        :leave-to-class="$style.fadeInvisible"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <ModalContainer>
      <template #overlay>
        <div :class="$style.modalOverlay"></div>
      </template>
    </ModalContainer>
    <SnackContainer :class="$style.snackContainer" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ModalContainer, SnackContainer } from "vue-haystack";

import { useTenants } from "@/stores/tenants";

const tenants = useTenants();

const tenantStyles = computed(() => ({
  "--tenant-primary": tenants.activeTenant.primaryColor ?? undefined,
  "--tenant-secondary": tenants.activeTenant.secondaryColor ?? undefined,
}));
</script>

<style lang="scss">
@import "./styles/fonts.scss";

:root {
  --header-height: 56px;
  --tenant-primary: #{$primary};
  --tenant-secondary: #{$secondary};
}
</style>

<style lang="scss" module>
.modalOverlay {
  width: 100%;
  height: 100%;
  background-color: #1c1c1c;
  opacity: 0.5;
}

.snackContainer {
  top: calc(var(--header-height) + #{map-get($map: $spacers-map, $key: s)});
  right: + #{map-get($map: $spacers-map, $key: s)};
}

.fadeEnterActive {
  transition: opacity 0.1s ease-in;
}

.fadeLeaveActive {
  transition: opacity 0.1s ease-out;
}

.fadeInvisible {
  opacity: 0;
}
</style>
