export type PossibleTypesResultData = {
  possibleTypes: {
    Answer: ["MultipleChoiceAnswer", "TextAnswer"];
    Node: [
      "Candidate",
      "CandidateImport",
      "Client",
      "MetadataQuestion",
      "MultipleChoiceAnswer",
      "MultipleChoiceQuestion",
      "Participation",
      "Project",
      "Survey",
      "TextAnswer",
      "TextQuestion",
    ];
    Question: ["MetadataQuestion", "MultipleChoiceQuestion", "TextQuestion"];
    Resource: [
      "Candidate",
      "CandidateImport",
      "Client",
      "MetadataQuestion",
      "MultipleChoiceAnswer",
      "MultipleChoiceQuestion",
      "Participation",
      "Project",
      "Survey",
      "TextAnswer",
      "TextQuestion",
    ];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    Answer: ["MultipleChoiceAnswer", "TextAnswer"],
    Node: [
      "Candidate",
      "CandidateImport",
      "Client",
      "MetadataQuestion",
      "MultipleChoiceAnswer",
      "MultipleChoiceQuestion",
      "Participation",
      "Project",
      "Survey",
      "TextAnswer",
      "TextQuestion",
    ],
    Question: ["MetadataQuestion", "MultipleChoiceQuestion", "TextQuestion"],
    Resource: [
      "Candidate",
      "CandidateImport",
      "Client",
      "MetadataQuestion",
      "MultipleChoiceAnswer",
      "MultipleChoiceQuestion",
      "Participation",
      "Project",
      "Survey",
      "TextAnswer",
      "TextQuestion",
    ],
  },
};
export default result;
