<template>
  <div class="p-s" :class="[$style.baseSnack, $style[type]]" role="snackbarToast">
    <div class="d-flex gap-xs">
      <SvgIcon v-if="icon" class="flex-grow-0 flex-shrink-0" :class="$style.icon" :icon="icon" size="24px" />
      <div :class="$style.content" class="pt-xxs flex-grow-1 flex-shrink-1 d-flex flex-column align-items-start">
        <slot />
      </div>
      <SvgIcon class="flex-grow-0 flex-shrink-0" :class="$style.close" icon="x" @click="snack?.close" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useSnack } from "vue-haystack";

import type { SnackType } from "@/components/snackType";
import SvgIcon from "@/components/SvgIcon.vue";

const props = withDefaults(
  defineProps<{
    type?: SnackType;
  }>(),
  {
    type: "neutral",
  },
);

const snack = useSnack();

const icon = computed(() => {
  switch (props.type) {
    case "error":
      return "x-circle";
    case "warn":
      return "alert-circle";
    case "success":
      return "check-circle";
    case "info":
      return "help-circle";
  }
  return null;
});
</script>

<style module lang="scss">
.baseSnack {
  position: relative;
  width: 100vw;
  max-width: 320px;
  background-color: $white;
  box-sizing: border-box;
  border: 1px solid $grey-three;
  border-radius: $default-border-radius;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    box-shadow: 0 2px 6px rgba($grey-one, 0.2);
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    @extend %label-six;
  }
}

.close {
  cursor: pointer;
}

.error {
  border-color: $signalred;
  .icon {
    color: $signalred;
  }
}
.success {
  border-color: $signalgreen;
  .icon {
    color: $signalgreen;
  }
}
.warn {
  border-color: $signalorange;
  .icon {
    color: $signalorange;
  }
}
.info {
  border-color: $signalblue;
  .icon {
    color: $signalblue;
  }
}
</style>
