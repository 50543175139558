import { defineStore } from "pinia";
import type { Ref } from "vue";
import { computed, ref, watch } from "vue";

import type { SurveyTenantDataFragment } from "@/stores/tenants/tenantDataFragments.generated";

import type { ClientCorporateConfiguration } from "../../types/api-types";

interface TenantInfo {
  id: string;
  name: string;
}

export type TenantClientConfig = Pick<ClientCorporateConfiguration, "logo" | "primaryColor" | "secondaryColor">;

export type Tenant = TenantInfo & Partial<TenantClientConfig>;

export const defaultValues = {
  primaryColor: "#e63838",
  secondaryColor: "#f0f1f1",
};

export const useTenants = defineStore("tenants", () => {
  const pullsTenant: Tenant = {
    id: "pulls",
    name: "Pulls",
  };
  const customerTenants = ref<Tenant[]>([]);
  const activeTenantId = ref(pullsTenant.id);
  const activeTenant = computed(() => customerTenants.value.find((t) => t.id === activeTenantId.value) ?? pullsTenant);

  const addOrReplace = (tenant: Tenant) => {
    const prevTenantIndex = customerTenants.value.findIndex((t) => t.id === tenant.id);
    if (prevTenantIndex !== -1) {
      customerTenants.value[prevTenantIndex] = tenant;
      return;
    }
    customerTenants.value.push(tenant);
  };

  return {
    pullsTenant,
    customerTenants,
    activeTenantId,
    activeTenant,
    addOrReplace,
  };
});

export const useSurveyTenant = (survey: Ref<SurveyTenantDataFragment | null>) => {
  const tenants = useTenants();

  const tenant = computed<Tenant | null>(() => {
    if (!survey.value) {
      return null;
    }

    return {
      id: survey.value.id,
      name: survey.value.name,
      ...survey.value.inheritedCorporateConfiguration,
    };
  });

  watch(tenant, () => {
    if (tenant.value) {
      tenants.addOrReplace(tenant.value);
      tenants.activeTenantId = tenant.value.id;
    }
  });

  return tenant;
};
