import type { Ref } from "vue";
import { computed } from "vue";
import { useSnackbar } from "vue-haystack";

import MessageSnack from "@/components/MessageSnack.vue";
import ValidationMessagesSnack from "@/components/ValidationMessagesSnack.vue";
import { gettext } from "@/core/translations";

import type { ValidationMessage } from "../../types/api-types";

export const combinedLoading = (...loadingRefs: Ref<boolean>[]) =>
  computed(() => loadingRefs.some((r) => r.value === true));

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === "string") {
    return error;
  }
  return null;
};

export const handleApiErrorWithToast = (error: unknown) => {
  const snackbar = useSnackbar();

  if (Array.isArray(error)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const errorsAreValidationMessages = error.every(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (err) => "__typename" in err && err.__typename === "ValidationMessage",
    );
    if (errorsAreValidationMessages) {
      snackbar.push(
        ValidationMessagesSnack,
        { type: "error", messages: error as Partial<ValidationMessage>[] },
        undefined,
      );
      throw error;
    }
  }

  const errorMsg = getErrorMessage(error);
  snackbar.push(
    MessageSnack,
    { type: "error", message: errorMsg ? errorMsg : gettext.$gettext("Unerwarteter Fehler") },
    undefined,
    { time: 5000 },
  );

  throw error;
};

export const handleResult = <
  T extends { successful: boolean; messages?: (Partial<ValidationMessage> | null)[] | null },
>(
  resData: T | null | undefined,
) => {
  if (!resData) {
    throw new Error("Unexpected Error: No data received");
  }
  if (resData.successful) {
    return resData;
  }
  throw resData.messages;
};
