import "./styles/bootstrap.scss";
import "./styles/titles.scss";
import "vue-haystack/style.css";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import { createOidc } from "vue3-oidc";

import { provideApis } from "@/api";
import { router } from "@/router";

import App from "./App.vue";
import { oidcSettings } from "./core/oidc";
import { useSentry } from "./core/sentry";
import { useLogger } from "./core/services";
import { gettext } from "./core/translations";
import { handleError } from "./core/utils/handleError";

const logger = useLogger("main");

createOidc({
  oidcSettings: oidcSettings,
  auth: false,

  events: {
    addUserSignedIn: () => {
      logger.debug("addUserSignedIn")();
    },
    addUserLoaded: (user) => {
      logger.debug("addUserLoaded", user)();
    },
  },
  refreshToken: {
    enable: true,
    time: 10 * 1000,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

provideApis(app.provide.bind(app));

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(gettext);
app.use(router);

useSentry(app, router);

// Default Error Handler
app.config.errorHandler = (error) => handleError(error);
// Handle Errors outside Vue
window.addEventListener("error", (error) => handleError(error));
// Handle errors in Promises that were not caught (for example async watchers)
window.addEventListener("unhandledrejection", (error) => handleError(error));

app.mount("#app");
