import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";

import { sentryConfig } from "../config";
import { useLogger } from "../services";

const logger = useLogger("core/sentry");

const useSentry = (app: App, router: Router) => {
  if (sentryConfig.enabled) {
    logger.debug("Sentry is tracking...");
    Sentry.init({
      app,
      enabled: sentryConfig.enabled,
      dsn: sentryConfig.dsn,
      environment: sentryConfig.env,
      release: `${sentryConfig.projectName}/${sentryConfig.version ?? ""}`,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracesSampleRate: 1,
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: ["localhost"],
      autoSessionTracking: false,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      trackComponents: true,
    });
  } else {
    logger.debug("Sentry is NOT tracking...");
  }
};

export { useSentry };
