import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import type * as Types from "../../../types/api-types";

export type ParticipationQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type ParticipationQuery = { __typename?: "RootQueryType" } & {
  node: Types.Maybe<
    | { __typename: "Candidate" }
    | { __typename: "CandidateImport" }
    | { __typename: "Client" }
    | { __typename: "MetadataQuestion" }
    | { __typename: "MultipleChoiceAnswer" }
    | { __typename: "MultipleChoiceQuestion" }
    | ({ __typename: "Participation" } & Pick<
        Types.Participation,
        "id" | "hasNextTopic" | "hasPreviousTopic" | "participationToken"
      > & {
          survey: { __typename?: "Survey" } & Pick<
            Types.Survey,
            | "id"
            | "showPullsBranding"
            | "footerMarkdown"
            | "outroMarkdown"
            | "endButtonText"
            | "outroVideo"
            | "showResultsFromPrecedingSurvey"
            | "name"
          > & {
              outroImageSmX1: Types.Survey["outroImage"];
              outroImageSmX2: Types.Survey["outroImage"];
              outroImageMdX1: Types.Survey["outroImage"];
              outroImageMdX2: Types.Survey["outroImage"];
              backgroundImageX1: Types.Survey["backgroundImage"];
              backgroundImageX2: Types.Survey["backgroundImage"];
            } & {
              corporateConfiguration: Types.Maybe<
                { __typename?: "SurveyCorporateConfiguration" } & {
                  logoX1: Types.SurveyCorporateConfiguration["logo"];
                  logoX2: Types.SurveyCorporateConfiguration["logo"];
                }
              >;
              precedingSurvey: Types.Maybe<{ __typename?: "Survey" } & Pick<Types.Survey, "id" | "resultUrl">>;
              inheritedCorporateConfiguration: { __typename?: "SurveyCorporateConfiguration" } & Pick<
                Types.SurveyCorporateConfiguration,
                "logo" | "primaryColor" | "secondaryColor"
              >;
            };
          currentTopic: Types.Maybe<
            { __typename?: "Topic" } & {
              question:
                | ({ __typename?: "MetadataQuestion" } & Pick<Types.MetadataQuestion, "id" | "title" | "description">)
                | ({ __typename?: "MultipleChoiceQuestion" } & Pick<
                    Types.MultipleChoiceQuestion,
                    "choices" | "description" | "id" | "title"
                  >)
                | ({ __typename?: "TextQuestion" } & Pick<Types.TextQuestion, "description" | "id" | "title">);
              answer: Types.Maybe<
                | ({ __typename?: "MultipleChoiceAnswer" } & Pick<Types.MultipleChoiceAnswer, "id"> & {
                      values: Types.MultipleChoiceAnswer["value"];
                    })
                | ({ __typename?: "TextAnswer" } & Pick<Types.TextAnswer, "id" | "value">)
              >;
            }
          >;
        })
    | { __typename: "Project" }
    | { __typename: "Survey" }
    | { __typename: "TextAnswer" }
    | { __typename: "TextQuestion" }
  >;
};

export const ParticipationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "participation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "node" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Participation" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "participationData" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "survey" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "surveyTenantData" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "participationData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Participation" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "hasNextTopic" } },
          { kind: "Field", name: { kind: "Name", value: "hasPreviousTopic" } },
          { kind: "Field", name: { kind: "Name", value: "participationToken" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentTopic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "question" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MultipleChoiceQuestion" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "choices" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextQuestion" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "description" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "answer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextAnswer" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "value" } },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MultipleChoiceAnswer" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "values" },
                              name: { kind: "Name", value: "value" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "survey" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "showPullsBranding" } },
                { kind: "Field", name: { kind: "Name", value: "footerMarkdown" } },
                { kind: "Field", name: { kind: "Name", value: "outroMarkdown" } },
                { kind: "Field", name: { kind: "Name", value: "endButtonText" } },
                { kind: "Field", name: { kind: "Name", value: "footerMarkdown" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "outroImageSmX1" },
                  name: { kind: "Name", value: "outroImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_SM" },
                    },
                  ],
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "outroImageSmX2" },
                  name: { kind: "Name", value: "outroImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_SM_X2" },
                    },
                  ],
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "outroImageMdX1" },
                  name: { kind: "Name", value: "outroImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_MD" },
                    },
                  ],
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "outroImageMdX2" },
                  name: { kind: "Name", value: "outroImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_MD_X2" },
                    },
                  ],
                },
                { kind: "Field", name: { kind: "Name", value: "outroVideo" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "backgroundImageX1" },
                  name: { kind: "Name", value: "backgroundImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_LG" },
                    },
                  ],
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "backgroundImageX2" },
                  name: { kind: "Name", value: "backgroundImage" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "BACKGROUND_LG_X2" },
                    },
                  ],
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "corporateConfiguration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "logoX1" },
                        name: { kind: "Name", value: "logo" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "size" },
                            value: { kind: "EnumValue", value: "SMALL" },
                          },
                        ],
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "logoX2" },
                        name: { kind: "Name", value: "logo" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "size" },
                            value: { kind: "EnumValue", value: "SMALL_X2" },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "precedingSurvey" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "resultUrl" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "showResultsFromPrecedingSurvey" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "surveyTenantData" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Survey" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "inheritedCorporateConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "logo" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "size" },
                      value: { kind: "EnumValue", value: "SMALL_X2" },
                    },
                  ],
                },
                { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
                { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ParticipationQuery, ParticipationQueryVariables>;
