import { defineStore } from "pinia";
import { ref } from "vue";

export const useParticipationAuth = defineStore(
  "auth",
  () => {
    const guestOrParticipationToken = ref<string>();

    return {
      guestOrParticipationToken,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
);
