import type { ImportMetaEnv } from "../config/env";
import { useLogger } from "../services";

const logger = useLogger("core/runtimeData");

export function getData(dataSelect: string): Partial<ImportMetaEnv> {
  try {
    if (typeof document === "undefined") {
      return {};
    }
    const inlineJsonElement = document.querySelector(`script[type="application/json"][data-selector="${dataSelect}"]`);
    if (!inlineJsonElement?.textContent) {
      return {};
    }
    if (inlineJsonElement.textContent.trim() === "// RUNTIME_CONFIGURATION") {
      return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(inlineJsonElement.textContent);
  } catch (err) {
    logger.error(`Couldn't read JSON data from ${dataSelect}`, err)();
    return {};
  }
}

export function getRuntimeConfig(): Partial<ImportMetaEnv> {
  try {
    const runtimeConfig = getData("runtime-config");
    logger.debug("Runtime config was fetched from meta tag.", runtimeConfig)();
    return runtimeConfig;
  } catch (error) {
    logger.debug("Runtime config could not be fetched from meta tag.", error)();
    return {};
  }
}
