import { useApolloClient } from "@vue/apollo-composable";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

import { assertIsDefined, assertTypename } from "@/core/utils/typeHelpers";
import { handleApiErrorWithToast } from "@/helpers/graphql";
import type { ParticipationQuery } from "@/stores/participation/participation.generated";
import { ParticipationDocument } from "@/stores/participation/participation.generated";
import { SurveyOrParticipationDocument } from "@/stores/participation/surveyOrParticipation.generated";
import { useSurveyTenant } from "@/stores/tenants";

export const fetchSurveyOrParticipation = async (jwt: string) => {
  let surveyOrParticipationId: string;
  try {
    surveyOrParticipationId = jwtDecode<{ id: string }>(jwt).id;
    if (!surveyOrParticipationId) {
      throw new Error("Unexpected error: Failed to find id in token");
    }
  } catch {
    throw new Error("Failed to parse token");
  }

  const res = await useApolloClient().client.query({
    query: SurveyOrParticipationDocument,
    variables: { id: surveyOrParticipationId },
  });
  if (!res) {
    throw new Error("Unexpected errror: Failed to load survey or participation");
  }
  return res.data.node;
};

const loadParticipation = async (id: string) => {
  return await useApolloClient().client.query({
    query: ParticipationDocument,
    variables: { id },
  });
};

export const useParticipation = defineStore("participation", () => {
  const participationId = ref<string>();

  const result = ref<ParticipationQuery>();
  const loading = ref(false);

  const fetch = async () => {
    assertIsDefined(participationId.value, "participationId");
    try {
      loading.value = true;
      result.value = (await loadParticipation(participationId.value)).data;
    } catch (e) {
      result.value = undefined;
      handleApiErrorWithToast(e);
    } finally {
      loading.value = false;
    }
  };

  watch(
    participationId,
    () => {
      if (participationId.value) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch();
      }
    },
    {
      immediate: true,
    },
  );

  const participation = computed(() => {
    if (!participationId.value || !result.value?.node) {
      return null;
    }
    assertTypename("Participation", result.value.node);
    return result.value.node;
  });

  const survey = computed(() => participation.value?.survey ?? null);
  useSurveyTenant(survey);

  return {
    participationId,
    participation,
    loading,
    refetch: fetch,
  };
});
