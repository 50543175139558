import type { GetTextOptions } from "vue3-gettext";

import { useLogger } from "../services";
import { getRuntimeConfig } from "../utils/runtimeData";
import { assertIsDefined } from "./../utils/typeHelpers";
import type { ImportMetaEnv } from "./env";

// Environment variable values in:
// -------------------------------
// .env (and .env.dist)
// libs/core/src/config/env.d.ts

const logger = useLogger("core/config");
const dynamicRuntimeConfig = getRuntimeConfig();

// --- Base Config ---

const defaultBaseConfig: Pick<ImportMetaEnv, "NODE_ENV" | "VITE_APP_URL"> = {
  NODE_ENV: "development",
  VITE_APP_URL: "http://localhost:8080",
};

const rawBaseConfig = {
  nodeEnv: getString("NODE_ENV", defaultBaseConfig),
  appUrl: getString("VITE_APP_URL", defaultBaseConfig),
};

const baseConfig = {
  nodeEnv: rawBaseConfig.nodeEnv,
  appUrl: rawBaseConfig.appUrl,
};

logger.debug("baseConfig", baseConfig)();

export { baseConfig };

// --- API Config ---

const defaultApiConfig: Pick<ImportMetaEnv, "VITE_APP_APIS_BACKEND_API_URL"> = {
  VITE_APP_APIS_BACKEND_API_URL: "/", // TODO: Add default value
};

const rawApiConfig = {
  backend: {
    api: getString("VITE_APP_APIS_BACKEND_API_URL", defaultApiConfig),
  },
};

const apiConfig = {
  backend: {
    api: rawApiConfig.backend.api,
  },
};

logger.debug("apiConfig", apiConfig)();

export { apiConfig };

// --- I18N Config ---

const defaultI18nConfig: Pick<ImportMetaEnv, "VITE_APP_I18N_DEFAULT_LANGUAGE"> = {
  VITE_APP_I18N_DEFAULT_LANGUAGE: "de_CH",
};

const rawI18nConfig = {
  VITE_APP_I18N_DEFAULT_LANGUAGE: getString("VITE_APP_I18N_DEFAULT_LANGUAGE", defaultI18nConfig),
};

assertIsDefined(rawI18nConfig.VITE_APP_I18N_DEFAULT_LANGUAGE, "VITE_APP_I18N_DEFAULT_LANGUAGE");

const i18nConfig: Pick<GetTextOptions, "defaultLanguage" | "availableLanguages" | "mutedLanguages"> = {
  defaultLanguage: rawI18nConfig.VITE_APP_I18N_DEFAULT_LANGUAGE,
  availableLanguages: {
    de_CH: "German (Switzerland)",
  },
  mutedLanguages: ["de_CH"],
};

logger.debug("i18nConfig", i18nConfig)();

export { i18nConfig };

// --- Auth ---

const defaultAuthConfig: Pick<ImportMetaEnv, "VITE_APP_OIDC_CLIENT_ID" | "VITE_APP_OIDC_AUTHORITY"> = {
  VITE_APP_OIDC_CLIENT_ID: undefined,
  VITE_APP_OIDC_AUTHORITY: undefined,
};

const rawAuthConfig = {
  VITE_APP_OIDC_CLIENT_ID: getString("VITE_APP_OIDC_CLIENT_ID", defaultAuthConfig, true),
  VITE_APP_OIDC_AUTHORITY: getString("VITE_APP_OIDC_AUTHORITY", defaultAuthConfig, true),
};

assertIsDefined(rawAuthConfig.VITE_APP_OIDC_CLIENT_ID, "rawAuthConfig.VITE_APP_OIDC_CLIENT_ID");
assertIsDefined(rawAuthConfig.VITE_APP_OIDC_AUTHORITY, "rawAuthConfig.VITE_APP_OIDC_AUTHORITY");

const authConfig = {
  oidcClientId: rawAuthConfig.VITE_APP_OIDC_CLIENT_ID,
  oidcAuthority: rawAuthConfig.VITE_APP_OIDC_AUTHORITY,
};

logger.debug("authConfig", authConfig)();

export { authConfig };

// --- Sentry Config ---

const defaultSentryConfig: Pick<ImportMetaEnv, "VITE_APP_SENTRY_ENABLED"> = {
  VITE_APP_SENTRY_ENABLED: "false",
};

const rawSentryConfig = {
  VITE_APP_SENTRY_ENABLED: getBool("VITE_APP_SENTRY_ENABLED", defaultSentryConfig, true),
  VITE_APP_SENTRY_DSN: getString("VITE_APP_SENTRY_DSN", defaultSentryConfig),
  VITE_APP_SENTRY_ENVIRONMENT: getString("VITE_APP_SENTRY_ENVIRONMENT", defaultSentryConfig),
  VITE_APP_SENTRY_PROJECT_NAME: getString("VITE_APP_SENTRY_PROJECT_NAME", defaultSentryConfig),
  VITE_APP_SENTRY_VERSION: getString("VITE_APP_SENTRY_VERSION", defaultSentryConfig),
};

assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_ENABLED, "rawSentryConfig.VITE_APP_SENTRY_ENABLED");

if (rawSentryConfig.VITE_APP_SENTRY_ENABLED === true) {
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_DSN, "VITE_APP_SENTRY_DSN");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_ENVIRONMENT, "VITE_APP_SENTRY_ENVIRONMENT");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_PROJECT_NAME, "VITE_APP_SENTRY_PROJECT_NAME");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_VERSION, "VITE_APP_SENTRY_VERSION");
}

const sentryConfig = {
  enabled: rawSentryConfig.VITE_APP_SENTRY_ENABLED,
  dsn: rawSentryConfig.VITE_APP_SENTRY_DSN,
  projectName: rawSentryConfig.VITE_APP_SENTRY_PROJECT_NAME,
  env: rawSentryConfig.VITE_APP_SENTRY_ENVIRONMENT,
  version: rawSentryConfig.VITE_APP_SENTRY_VERSION,
};

logger.debug("sentryConfig", sentryConfig)();

export { sentryConfig };

// export type { RuntimeConfig };

// --- Utility ---

function getString(
  key: keyof ImportMetaEnv,
  defaultConfig: Partial<ImportMetaEnv>,
  required = false,
): string | undefined {
  let value: string | undefined;
  if (defaultConfig[key] !== undefined) {
    logger.debug("Checking for key in defaultConfig: ", key, defaultConfig[key]);
    value = String(defaultConfig[key]);
  }
  if (import.meta.env[key] !== undefined) {
    logger.debug("Checking for key in import.meta.env: ", key, import.meta.env[key]);
    value = String(import.meta.env[key]);
  }
  if (dynamicRuntimeConfig[key] !== undefined) {
    logger.debug("Checking for key in dynamicRuntimeConfig: ", key, dynamicRuntimeConfig[key]);
    value = String(dynamicRuntimeConfig[key]);
  }
  if (required) {
    assertIsDefined(value, key.toString());
    if (!value) {
      throw new Error(`Missing required value for config variable "${key}"`);
    }
  }
  return value;
}

function getBool(
  key: keyof ImportMetaEnv,
  defaultConfig: Partial<ImportMetaEnv>,
  required = false,
): boolean | undefined {
  const val = getString(key, defaultConfig, required);
  let value: boolean | undefined;
  if (typeof val === "string") {
    value = val.toLowerCase() === "true";
  }
  if (required) {
    assertIsDefined(value, key.toString());
    if (!value && typeof value !== "boolean") {
      throw new Error(`Missing required value for config variable "${key}"`);
    }
  }
  if (typeof value !== "boolean" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a boolean or a string, is "${typeof value}"`,
    );
  }
  return value;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getArray(
  key: keyof ImportMetaEnv,
  defaultConfig: Partial<ImportMetaEnv>,
  required = false,
): string[] | undefined {
  const val = getString(key, defaultConfig, required);
  let value: string[] | undefined;
  if (typeof val !== "string" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a string (separated with commas), is "${typeof val}"`,
    );
  }
  if (typeof val === "string") {
    value = val.split(",");
  }
  if (required) {
    assertIsDefined(value, key.toString());
    if (!value) {
      throw new Error(`Missing required value for config variable "${key}"`);
    }
  }
  if (!Array.isArray(value) && required) {
    throw new Error(`Value for config variable "${key}" is required and expected to be an array, is "${typeof val}"`);
  }
  return value;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getInt(key: keyof ImportMetaEnv, defaultConfig: Partial<ImportMetaEnv>, required = false): number | undefined {
  const val = getString(key, defaultConfig, required);
  let value: number | undefined;
  if (typeof val === "number") {
    value = val;
  }
  if (typeof val === "string") {
    value = Number(val);
  }
  if (typeof value !== "number" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a number or a string, is "${typeof value}"`,
    );
  }
  return value;
}
