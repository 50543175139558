<template>
  <component :is="iconSvg" :key="icon" :class="$style.icon" :style="css"></component>
</template>

<script lang="ts" setup>
import type { FunctionalComponent, SVGAttributes } from "vue";
import { computed } from "vue";

const iconModules = import.meta.glob("../assets/icons/*.svg", {
  eager: true,
  query: "?component",
});

const iconMap = Object.entries(iconModules).reduce<Record<string, FunctionalComponent>>((prev, icon) => {
  const [iconPath, iconModule] = icon;

  const iconName = iconPath.split("/").pop()?.split(".svg")[0];
  if (!iconName) {
    console.warn(`Invalid iconPath: ${iconPath}`);
    return prev;
  }
  return {
    ...prev,
    [iconName]: iconModule as FunctionalComponent,
  };
}, {});

const props = withDefaults(
  defineProps<{
    icon: string;
    size?: string;
  }>(),
  {
    size: "22px",
  },
);

const css = computed(() => ({
  "--size": props.size,
}));

const iconSvg = computed((): FunctionalComponent<SVGAttributes> | null => {
  if (props.icon === null) {
    return null;
  }
  const icon = iconMap[props.icon];
  if (!icon) {
    console.warn("Icon not found: " + props.icon);
    return null;
  }
  return icon;
});
</script>

<style module lang="scss">
.icon {
  width: var(--size);
  height: var(--size);
  display: inline-block;
}
</style>
