import { AssertionError } from "assertion-error";

export function ensureNoNullsAndUndefineds<T>(items: (T | null | undefined)[]) {
  return items.filter((x): x is T => x !== null && x !== undefined);
}

export function assertNonNullable<T>(val: T): asserts val is NonNullable<T> {
  if (val === null) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call
    throw new AssertionError(`Expected 'val' to not be nullable, but received ${val}`);
  }
}

export function assertIsDefined<T>(val: T, variableName: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new AssertionError(`Expected ${variableName} to be defined, but received ${val}`);
  }
}

export const isTypename =
  <T extends string>(typename: T) =>
  <N extends { __typename: string }>(node?: N): node is Extract<N, { __typename: T }> => {
    return node?.__typename === typename || false;
  };

export function assertTypename<T extends string, N extends { __typename: T }>(
  typename: T,
  node?: { __typename: string } | null,
): asserts node is N & { __typename: T } {
  if (!isTypename(typename)(node || undefined)) {
    throw new AssertionError(
      `Expected '__typename' to be ${typename}, but received ${node?.__typename ?? "undefined"}`,
    );
  }
}

export type RequiredNotNullOrUndefined<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

export const filterNullables = <T>(item?: T | null): item is T => item !== null && item !== undefined;

export const extractNodeAsArray = <N>(edge: { node?: N | null }) => (edge.node ? [edge.node] : []);

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
